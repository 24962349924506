import React, { useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Link, { EmailLink } from 'artkive/pages/Privacy/Link'
import routes from 'artkive/utils/routes'

const useStyles = makeStyles({
  listWrapper: {
    lineHeight: '120%',
  },
  paddingTop: {
    '&::before': {
      content: "' '",
      display: 'block',
      paddingTop: '80px',
      visibility: 'hidden',
    },
  },
})

export const LATEST_TOU_BOX_ID = 'tou'

const LatestBody = () => {
  const classes = useStyles()

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://consent.cookiebot.com/8ccb5458-ee3e-4788-a788-01b997ce8367/cd.js'
    script.async = true
    script.id = 'CookieDeclaration'

    document.getElementById(LATEST_TOU_BOX_ID).appendChild(script)

    return () => {
      document.getElementById(LATEST_TOU_BOX_ID).removeChild(script)
    }
  }, [])

  return (
    <Box id={LATEST_TOU_BOX_ID}>
      <span id={'updated-terms-of-use'} className={classes.paddingTop} />
      <Typography align={'center'} variant={'h3'}>Updated Terms of Use</Typography>
      <br />
      <Typography marginBottom={true}>
        THESE “TERMS OF USE” APPLY TO YOU, AN INDIVIDUAL USER ACCESSING ARTKIVEAPP.COM, CARDSBYARTKIVE.COM,
        INTERNATIONAL.ARTKIVEBOX.COM, PHOTOSBYARTKIVE.COM, OR ANY OTHER WEBSITE OPERATED BY THE KIVE COMPANY ON
        WHICH YOU FIND THESE TERMS OF USE (TOGETHER, THE “WEBSITE”) OR THE ARTKIVE APPLICATION AVAILABLE ON THE
        APPLE APP STORE (“APP”), AND THE KIVE COMPANY (“COMPANY”). IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT
        ACCESS OR USE THE WEBSITE OR APP.
      </Typography>

      <br />

      <Typography marginBottom={true}>
        <b>
          THIS SUMMARIZES YOUR FINANCIAL OBLIGATIONS WHEN YOU USE OUR ARTKIVE BOX SERVICE. (PLEASE SCROLL DOWN FOR
          THE FULL TERMS.)
        </b>
      </Typography>

      <ul className={classes.listWrapper}>
        <li>
          <b>THE ARTKIVE BOX SERVICE REQUIRES YOU TO MAKE TWO PAYMENTS.</b>
        </li>
        <li>
          <u>PAYMENT 1</u>: <b>AN INITIAL PAYMENT IS MADE AT THE TIME OF YOUR PURCHASE OF THE ARTKIVE BOX
          SERVICE</b> (WHICH INCLUDES A PHYSICAL BOX FOR YOU TO SHIP YOUR ARTWORK IN AND PRE-PAID SHIPPING LABELS).
        </li>
        <li>
          <u>INVOICE</u>: <b>UPON RECEIPT OF YOUR ARTWORK, THE COMPANY WILL EMAIL AN INVOICE TO YOU BASED ON THE
          TOTAL NUMBER OF ITEMS YOU SEND IN YOUR ARTKIVE BOX AND THE ORDER YOU INDICATE ON YOUR ORDER FORM</b>.
          PLEASE REFER TO OUR <Link href={routes.pricing}>PRICING PAGE</Link> AT THE WEBSITE FOR ALL PRICING
          INFORMATION.
        </li>
        <ul>
          <li>
            AFTER SENDING YOUR ARTKIVE BOX BACK TO THE COMPANY, IF YOU DECIDE YOU NO LONGER WANT TO PRINT OUT ANY
            BOOKS OR MOSAICS, YOU WILL RECEIVE A REVISED INVOICE FOR THE STANDALONE DIGITIZATION FEE.
          </li>
          <li>
            <b>THE STANDALONE DIGITIZATION FEE IS $45 FOR FIRST 25 PHOTOGRAPHS OF YOUR ITEMS + $1.35 FOR EACH
              ADDITIONAL ITEM.</b>
          </li>
          <li>
            ALL ITEMS SENT TO THE COMPANY WILL BE DIGITIZED AND THIS PROCESS CANNOT BE CANCELLED ONCE ITEMS ARE SENT
            TO THE COMPANY.
          </li>
          <li>
            <b>AT A <i>MINIMUM</i>, YOU ARE RESPONSIBLE FOR THE DIGITIZATION OF ALL ITEMS SENT TO THE COMPANY.</b>
          </li>
        </ul>

        <li>
          <u>PAYMENT 2</u>: YOU ARE REQUIRED TO MAKE A <b>SUBSEQUENT PAYMENT</b> IN THE AMOUNT OF YOUR INVOICE.
          <ul>
            <li>
              IF YOU DO NOT MAKE A TIMELY PAYMENT WITHIN 30 DAYS OF RECEIVING YOUR INVOICE, THE COMPANY RESERVES THE
              RIGHT TO CHARGE YOUR METHOD OF PAYMENT FOR THE DIGITIZATION FEE NOTED ABOVE.
            </li>
            <li>
              IF WE ARE UNABLE TO COLLECT PAYMENT FOR AN UNPAID INVOICE, THE AMOUNT DUE SHALL ACCRUE INTEREST IN THE
              AMOUNT OF TEN PERCENT (10%) PER ANNUM (OR THE MAXIMUM NON-USURIOUS INTEREST RATE, IF LESSER) FROM THE
              DATE DUE UNTIL THE DATE PAID IN FULL AND YOU SHALL BE RESPONSIBLE FOR ALL ATTORNEYS’ AND COLLECTIONS’
              FEES INCURRED BY COMPANY ALLOWED BY LAW. PLEASE NOTE THAT COLLECTIONS’ FEES ARE TYPICALLY THIRTY
              PERCENT (30%) OF THE UNPAID AMOUNT.
            </li>
          </ul>
        </li>
      </ul>

      <br />

      <ol className={classes.listWrapper}>
        <li>
          <b>RESTRICTIONS.</b>
          <p>
            The Company’s services are only intended for art, cards, and photographs. <b>The Company will not accept
            potentially hazardous items including but not limited to glass, health/medical related items, teeth,
            hair, and items with mold or bugs</b>. Further, the Company reserves the right to refuse items it deems
            offensive, including but not limited to, items that the Company deems racist, prejudiced, violent, or
            pornographic.
          </p>
        </li>
        <li>
          <b>ARTKIVE BOX.</b>
          <ol type={'A'}>
            <li>
              <u>Two Payment Structure.</u>
              <ol type={'i'}>
                <li>
                  <u>The Artkive Box Service.</u> The Company’s standard Artkive Box service includes a box and
                  shipping label for you to use to ship your artwork to us. <b>It does not include digitization
                  fees, artwork return, or any other services (such as book printing), which are invoiced
                  separately.</b>
                </li>
                <li>
                  <u>Initial Payment</u>. Your credit card is charged for your Artkive Box at the time of your
                  order.
                </li>
                <li>
                  <u>Subsequent Payment</u>. After your artwork is shipped to the Company, <b>the Company will
                  provide you with an invoice for the products and services you selected on your Order Form, which
                  will be based on the actual number of photographs needed to capture your artwork</b>. Please
                  contact us immediately at <EmailLink /> if you believe that the invoice contains an error. If your
                  invoice is not paid within thirty (30) days, you will be automatically charged for the
                  digitization of all your items.
                </li>
                <li>
                  <u>Consult the Pricing Page</u>. The Company urges you to consult the Artkive Box <Link
                    href={routes.pricing}>pricing page</Link> at the Company’s website prior to shipping your Artkive
                  Box.
                </li>
              </ol>
            </li>
            <li>
              <u>Shipping with your own shipping materials.</u> If you ship any items to the Company in your own
              packaging, you acknowledge and agree that (a) you are solely responsible for any damage to contents
              arising from or related to your packaging and/or the shipment, (b) the Company may refuse delivery
              from a domestic location in its sole discretion, and (c) you may be subject to additional shipping
              fees when using a pre-paid shipping label generated by the Company.
            </li>
            <li>
              <u>Order Form</u>. Each Artkive Box contains an order form. <b>Please indicate your desired type and
              quantity of services on your order form and consult the <Link href={routes.pricing}>pricing page on
                the Website</Link> to calculate the total cost of services</b>. You may also contact us with your
              pricing questions.
            </li>
            <li>
              <u>Per-photograph digitization</u>. <b>Pricing is based on the number of photographs needed to
              digitize your items</b>. If you tape or glue multiple pieces of artwork on the same page, the Company
              treats this as one piece of art and takes one photograph. If your artwork is double-sided, it will
              require at least two photographs. It is also possible that a large or multi dimensional item will
              require more than one photograph to reasonably capture the item.
            </li>
            <li>
              <u>Domestic purchases</u>. If you order domestic Artkive Box books, you will be charged a book fee
              based on (a) the number of photographs required to capture your artwork and (b) the amount of books
              needed to feature all such items. Books must feature a minimum of 25 images and cannot accommodate
              more than 350. Additional copies of your books can be purchased for 60% of the original price for the
              book (not the discounted price, if applicable), which fee shall be subject to increase for additional
              copies purchased more than three (3) months after the original purchase. Please note: Users in Hawaii
              and Alaska may be responsible for additional shipping costs, which will be communicated during the
              checkout process.
            </li>
            <li>
              <u>International Purchases</u>. If you order an international book, the cost is the same as a domestic
              book purchase, plus $33 more per book.
            </li>
            <li>
              <u>Digitization without book orders. <b>Once any artwork is shipped to us, you acknowledge and agree
                that if you choose not to purchase any books or mosaics, you will be invoiced for (and are required
                to pay in full) the cost to professionally photograph and digitize ALL artwork you provide
                and</b></u>:
              <ol type={'i'}>
                <li>
                  <u><b>You cannot cancel or limit our photography and digitation once you ship your items to
                    us;</b></u>
                </li>
                <li>
                  <u><b>The minimum digitization fee is $45.00 and covers the first 25 photographs of your
                    artwork</b></u>; and
                </li>
                <li>
                  <b>If more than 25 photographs are required, in addition to the cost of the Artkive Box (if
                    purchased) and the $45 minimum charge, <u>you will be charged $1.35 per image after the first
                      25.</u></b>
                </li>
                <li>
                  <i>For example</i>: If you send 200 pieces of art (that can each be captured with one photograph)
                  in your Artkive Box, and do not purchase any books or mosaics, you will be responsible for paying
                  the current cost of the Artkive Box (which you will pay upon ordering) <u>plus</u> $45.00 + ($1.35
                  x 175) (which will be invoiced to you) = $281.25.
                </li>
              </ol>
            </li>
            <li>
              <u>Digitization with a book order</u>. <b>For any image you elect not to include in your book order,
              you will be charged a digitization fee of $1.00/image.</b>
            </li>
            <li>
              <u>Automatic charge</u>. <b><u>If you do not pay your invoice within thirty (30) days, you will be
              automatically charged the digitization fee ($45 for the first 25 photographs + $1.35 for each
              additional photograph) for all artwork sent to the Company</u>. If you believe that you were charged
              in error, please contact us immediately at <EmailLink />.</b>
            </li>
            <li>
              <u>Failure to ship the Artkive Box to the Company</u>. The Artkive Box is not intended as a standalone
              item. However, if you do not ship your artwork to the Company, you will not be invoiced for
              digitization, a book, or mosaic, except to the extent you pre-paid for such service(s). If you would
              like to ship your artwork to us after the one-year anniversary of your Artkive Box purchase, you may
              ship it at your own costs or contact us for a new return label. If shipping costs have increased, you
              are responsible for covering any increased shipping costs incurred by the Company in providing a new
              return label.
            </li>
            <li>
              <u>Oversized Items</u>. If you wish to include your own photographs or Company photographs of items
              that do not fit in an Artkive Box, please contact the Company to discuss your options.
            </li>
          </ol>
        </li>
        <li>
          <b>DOMESTIC CARDS BOX AND PHOTO BOX SERVICES.</b>
          <ol type={'A'}>
            <li>
              <u>Flat Fee Structure</u>. The Company offers domestic flat fee Cards Box and Photo Box services for
              card and photo books, which include a box, shipping labels, digitization, and a book of all cards or
              photos sent to the Company in the box provided. Your credit card will be charged at the time of your
              order.
            </li>
            <li>
              <u>Limitations</u>.
              <ol type={'i'}>
                <li>
                  These services are limited to cards and photographs, as applicable. If you include art or non-card
                  or photo items, as applicable (each a “non-permitted item’), <b>you will be charged a digitization
                  fee of $1.35 for each non-permitted item included in the box</b>.
                </li>
                <li>
                  If you include envelopes or letters, these items will not be digitized unless you and the Company
                  have otherwise agreed in writing.
                </li>
                <li>
                  If you ship your items to the Company in your own packaging, you acknowledge and agree that (a)
                  you are solely responsible for any damage to contents arising from or related to your packaging
                  and/or the shipment, (b) the Company may refuse delivery in its sole discretion, (c) you may be
                  subject to additional shipping fees when using a pre-paid shipping label generated by the Company,
                  and (d) you will be subject to additional fees if the contents do not fit in the Company
                  designated boxes in your Cards Box or Photo Box order.
                </li>
              </ol>
            </li>
            <li>
              <u>Additional Charges</u>. If you are subject to additional fees, the Company will provide you with an
              invoice. The Company reserves the right to automatically charge you if you do not pay your invoice
              within thirty (30) days. If you believe that you were charged in error, please contact us immediately
              at <EmailLink />.
            </li>
            <li>
              <u>Oversized items</u>. If you wish to include your own photographs or Company photographs of items
              that do not fit in the box provided, please contact the Company to discuss your options. Typically,
              the Company can accommodate up to ten (10) of your own images in flat fee book orders.
            </li>
          </ol>
        </li>
        <li>
          <b>MOSAIC PURCHASES.</b>
          <p>
            If you order a domestic mosaic for not-yet-digitized art/cards/photos without ordering a book, you will
            be charged the applicable mosaic fee, based on 9 or 25 image mosaic, and <b>$1.35 for the
            digitization</b> of each art/card/photo item that was not included in the mosaic. Please note that the
            digitization process may result in more images than items if you provide double-sided or large items.
          </p>
        </li>
        <li>
          <b>ADD-ON AND DIY PURCHASES.</b>
          <p>
            If you order (a) a mosaic as an add-on to your book order or for images that have already been
            digitized, or (b) a Do-It-Yourself (DIY) book for images that have already been digitized, you will be
            charged the applicable add-on or DIY fee, which is less than the standard pricing that includes
            digitization.
          </p>
        </li>
        <li>
          <b>PRE-PAID BOOKS AND INTERNATIONAL CARD AND PHOTO BOOK PURCHASES.</b>
          <p>
            The Company offers certain domestic pre-paid book options and international card and photo book service
            that digitizes a set number of items and creates a book of all such items shipped to the Company in your
            own packaging. The service does not include a box or shipping label for the shipment of your items to
            the Company, but does include the cost of shipping the book to you. If you send more than the set
            number, you will be charged for an additional book service for each additional increment of the set
            number of items covered by the service. For <i>example</i>, if you order a 60-page book but send items
            that are digitized into 100 images, you will be invoiced for one additional book (two books total). If
            you include envelopes, the envelopes will not be digitized unless you and the Company have otherwise
            agreed in writing. Please note that the digitization process may result in more images than items if you
            provide double-sided or large items. If a service is labeled for Cards or Photos, and you include art in
            your shipment or non-permitted items, you will be charged a <b>digitization fee of $1.35 per
            photograph</b> and such art and/or non-permitted items will not be included in the book.
          </p>
        </li>
        <li>
          <b>ART/CARD/PHOTO RETURN.</b>
          <p>
            <b><u>The Company does not return your art, cards or photos unless you purchase a return service</u></b>.
            If you did not explicitly purchase the Company’s return service during your initial purchase, the
            Company will provide you with fifteen (15) days’ notice, to the email address you provided on your
            order, before your art/cards/photos are destroyed. You must place an order for the return of your
            art/cards/photos prior to expiration of the notice period.
          </p>
        </li>
        <li>
          <b>NON-STANDARD HANDLING CHARGES MAY APPLY.</b>
          <p>
            Additional fees may be assessed for non-standard handling, such as restoration and/or manipulation
            efforts. For example, if your art/cards/photos are compromised with tape, glue, or another material, the
            Company may need to restore or manipulate the items in order to digitize them. In such cases, the
            Company reserves the right to assess, and invoice you for, reasonable additional fees.
          </p>
        </li>
        <li>
          <b>MEMBERSHIP.</b>
          <p>
            If you have purchased a membership that is charged annually, after your initial payment, you will be
            automatically charged on each anniversary of your membership. Likewise, if you have purchased a
            membership that is charged monthly, after your initial payment, you will be automatically charged on
            each monthly anniversary. You will receive 30 days’ notice of any membership rate changes that apply to
            you. Except for a pro-rata refund that will be provided to you in the event Company terminates your
            membership for convenience, Membership fees are non-refundable. Please contact us at <EmailLink />.com
            regarding membership termination, which we will work in good faith to process before your next automatic
            billing date.
          </p>
        </li>
        <li>
          <b>COLLECTIONS.</b>
          <p>
            <u><b>If we are unable to collect payment for an unpaid invoice, the amount due shall accrue interest in
              the amount of ten percent (10%) per annum (or the maximum non-usurious interest rate, if lesser) from
              the date due until the date paid in full and you shall be responsible for all attorneys’ and
              collections’ fees incurred by Company allowed by law. Please note that collections’ fees are typically
              thirty percent (30%) of the unpaid amount.</b></u>
          </p>
        </li>
        <li>
          <b>ACCESS TO YOUR DIGITIZED ART.</b>
          <p>
            Once your art has been digitized and the Company has received payment in full, you will receive access
            to the digitized photographs in your account. Access to the digitized photographs expires (i) three (3)
            months from the date you received access, or (ii) three (3) months from the date your book or mosaic is
            printed, if applicable. To preserve your access to the digitized items longer, you may order a USB drive
            of your items from the Company, purchase a bulk download, or a Membership. Subject to its right to
            revoke access and/or delete the photographs in its sole discretion with thirty (30) days’ notice, the
            Company provides access to up to 39 pieces of art on a complimentary basis.
          </p>
        </li>
        <li>
          <b>DIY DISCLAIMER</b>
          <p>
            If you choose to use your own digital images rather than the Company’s digitization service for a book
            or mosaic, please note that the examples on the Company’s website are representative of books and
            mosaics created with professionally photographed and edited images and that your book and/or mosaic may
            not result in the same level of quality.
          </p>
        </li>
        <li>
          <b>ARTKIVE BOX, FLAT FEE BOOK, USB, AND ART RETURN CANCELATION</b>
          <p>
            So long as the Company has not processed your order, you may cancel your Artkive Box, flat fee book, USB
            purchase and/or art return purchase within one (1) business day. Please contact <EmailLink /> to inquire
            about cancelation. Upon cancelation, you will be refunded the amount you paid less a $5 cancelation fee.
          </p>
        </li>
        <li>
          <b>BOOK AND MOSAIC CANCELATION</b>
          <p>
            Once you approve your proof, your book and/or mosaic can no longer be cancelled and is non refundable.
            Prior to proof approval, you may cancel your book or mosaic subject to (A) payment of the digitization
            fee for any artwork provided to the Company that were not previously digitized, and (B) a $25/proof
            processing fee if a proof was already provided. Please contact us at <EmailLink /> to process your
            cancelation request.
          </p>
        </li>
        <li>
          <b>PROTECTION PACKAGES.</b>
          <p>
            From time to time, the Company many provide users the opportunity to purchase certain storage, warranty,
            and shipping protection packages. The terms of such packages will be communicated to you prior to your
            purchase. For all such warranty and protection packages, you acknowledge and agree that your failure to
            provide any photographic or other evidence reasonably required by the Company may result in the
            Company’s denial of your warranty or protection claim. If you have purchased a package that is charged
            monthly, after your initial payment, you will be automatically charged on each monthly anniversary.
            Likewise, if you have purchased a package that is charged annually, and automatically renews, after your
            initial payment, you will be automatically charged on each anniversary. You will receive 30 days’ notice
            of any rate changes that apply to you. Except for a pro-rata refund that will be provided to you in the
            event Company terminates your package for convenience, package fees are non-refundable. Please contact
            us at <EmailLink /> regarding termination, which we will work in good faith to process before your next
            automatic billing date.
          </p>
        </li>
        <li>
          <b>CREDITS</b>
          <p>
            Credits you receive through a referral program or memberships can typically be applied to book
            purchases, mosaics, and the second payment of Artkive boxes, but other restrictions may apply. Credits
            have no cash value and are non-transferrable. Please note that credits expire automatically on the one
            (1) year anniversary of your receipt of each credit.
          </p>
        </li>
        <li>
          <b>REFERRAL AND OTHER PROGRAMS</b>
          <p>
            From time to time, the Company may provide users the opportunity to participate in certain programs in
            exchange for certain perks, such as credits. These programs are only valid for so long as they are
            marketed on the website, or for thirty (30) days if they have been communicated to you via email. For
            referral programs, all referred persons must be new customers and use your referral link or code to
            qualify. If a referred customer cancels an order, your perk/credit will be rescinded. Benefits you
            receive expire automatically on the one (1) year anniversary of your receipt but may also be terminated
            by the Company with thirty (30) days’ notice.
          </p>
        </li>
        <li>
          <b>USER REPRESENTATIONS, WARRANTIES AND LIMITATIONS.</b>
          <ol type={'A'}>
            <li>
              <u>Representations and Warranties</u>. User represents, warrants, and covenants the following: (a)
              User shall not use its account to breach the security or gain access to the account of any other User
              and (b) These Terms of Use, upon use of the Services, will be valid, binding, and enforceable in
              accordance with its terms with respect to User.
            </li>
            <li>
              <u>Limitations</u>:
              <ol type={'i'}>
                <li>
                  Users must be at least eighteen (18) years old to access the Website and App services. If you are
                  not at least eighteen years old, you are not permitted to access the services for any reason.
                </li>
                <li>
                  Users may not circumvent any technological measures or features of the Services that are intended
                  to or effectively control access to the Services, or any other protected content or information
                  included in the Services.
                </li>
                <li>
                  The Website may contain robot exclusion headers. User agrees to not use any robot, spider,
                  crawler, scraper, or other automated means to access the Website for any purpose without the
                  Company's express prior written consent.
                </li>
                <li>
                  User further agrees to not interfere or attempt to interfere with the proper working of the
                  Website or App or any activities conducted on the Website or App.
                </li>
                <li>
                  The Company reserves the right to refuse the use of or access to any Company Services for any User
                  for any reason in the Company's sole discretion.
                </li>
              </ol>
            </li>
            <li>
              <b>LICENSE AND TERMINATION</b>
              <ol type={'A'}>
                <li>
                  The Company hereby grants to all Users a revocable, limited, non-assignable, non sublicensable,
                  nonexclusive license for the duration of User’s current viewing session to access, view and use
                  the Website and App, upload and access a limited number of art/cards/photos, and purchase the
                  purchase Company products and services, subject to the limitations set forth in these Terms of
                  Use.
                </li>
                <li>
                  The Company hereby grants to Company members a revocable, limited, non-assignable,
                  non-sublicensable, nonexclusive license to use the Website and App, to upload, access and share an
                  unlimited amount of art/cards/photos, and access other membership benefits, for the duration of
                  the membership period, subject to the limitations set forth in these Terms of Use.
                </li>
                <li>
                  The Company hereby grants to purchasers a revocable, limited, non-assignable, non sublicensable,
                  nonexclusive license to access such purchaser’s art/cards/photos as set forth in Section 1(E),
                  subject to the limitations set forth in these Terms of Use.
                </li>
                <li>
                  The licenses granted in subsections (A)-(C) above may be terminated immediately and without notice
                  by the Company. All rights not expressly granted in these Terms of Use are reserved. User agrees
                  that the license granted in these Terms of Use is personal to User, and User may not assign or
                  transfer any license granted under these Terms of Use or transfer any rights or delegate any
                  duties under these Terms of Use, to any third party. Any attempt to assign, transfer, or delegate
                  any of User's rights, duties, or obligations under these Terms of Use shall be void. User shall
                  indemnify and hold Company harmless for any unauthorized use of the services.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>INTELLECTUAL PROPERY; CONFIDENTIAL INFORMATION</b>
          <ol type={'A'}>
            <li>
              <u>Company IP</u>. User acknowledges and agrees that the trademarks of the Company, including but not
              limited to the logo and “ARTKIVE” wordmark (the “Marks”), the Website, the App, the look and feel of
              and content made accessible by the Website and App, and all newsletters, Company communications and
              Company social media posts (“Other Company IP”), to the extent protectable, are proprietary, original
              works of authorship of the Company, or licensors of the Company, protected under United States and
              worldwide copyright, trademark, and trade secret laws of general applicability. User further
              acknowledges and agrees that (i) all right, title and interest in and to the Marks and Other Company
              IP and shall remain with the Company, or its licensors, and (ii) it will not contest or infringe these
              rights, directly or indirectly, at any time. User also acknowledges and agrees that if it sends the
              Company any ideas or suggestions relating to the Website, App, services and Company, such ideas shall
              become property of the Company, and we will not be subject to any obligation of confidence nor liable
              for any use or disclosure of any submission. Please refrain from sending us any original creative
              ideas, suggestions, or materials, particularly those that are confidential or personal to you.
            </li>
            <li>
              <u>Confidential Information</u>. User understands that the tools, processes, strategies, materials,
              and information presented within the Services are copyrighted and proprietary, so User agrees not to
              record, duplicate, distribute, teach, or train from our information in any
            </li>
            <li>
              <u>User Content.</u>
              <ol type={'i'}>
                <li>
                  <u>Company license to user to provide services</u>. Upon uploading or posting photographic images,
                  videos or any descriptive text about a photograph or video within our App or on any Company
                  Instagram, Facebook, or social media page (“User Content”), User hereby grants to the Company a
                  non-exclusive, revocable, worldwide, royalty free license to display the User Content in
                  connection with Company's provision of the services. Such license shall be deemed granted as of
                  the moment of creation without the necessity of any further action on the part of either party.
                </li>
                <li>
                  <u>Option to grant permission to publish and distribute</u>. In the event Company has requested
                  permission from a User to publish and distribute any specific User Content, immediately upon
                  User's confirmation that permission is granted, User hereby grants to the Company a non-exclusive,
                  revocable, worldwide, royalty-free license to use, copy, sublicense through multiple tiers,
                  publish reproduce, prepare derivative works distribute and display such User Content and any
                  derivative works thereof. Revocation of such license shall not apply to Company's use of the User
                  Content to provide the User with use of the Company services or to any Company uses of the User
                  Content prior to the revocation.
                </li>
                <li>
                  <u>User Representations</u>. User represents and warrants to the Company with respect to the User
                  Content that the User Content, and the license of rights in and to the User Content, do not
                  infringe or violate any copyrights, trade secrets, or other intellectual or proprietary rights of
                  any third party, and that posting any User Content will not violate any applicable laws, rules, or
                  regulations. User shall indemnify and hold harmless the Company, and its officers, directors,
                  employees, shareholders, members, managers, and agents, from and against any and all claims,
                  demands, liabilities, and actions, including the payment of all legal expenses, including
                  reasonable attorney's fees and costs, arising out of or connected with any claim that the User
                  Content or any portion of the User Content infringes the rights of any third party or any other
                  damages arising from any use of, or reliance upon, the User Content by a third party, including
                  but not limited to other Users of these services. User Content includes but it not limited to all
                  data and content, including without limitation, all photographs, images, graphics, video
                  recordings, drawings, written descriptions, remarks, and narratives submitted by Users to the
                  Company.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>DISCLAIMERS; LINKS TO THIRD PARTY WEBSITES; INTERRUPTION TO THE SERVICES</b>
          <ol type={'A'}>
            <li>
              <u>No Warranties</u>. THE LICENSE GRANTED TO USER UNDER THESE TERMS OF USE IS PROVIDED ON AN “AS IS”
              AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTY OF ANY NATURE. THE COMPANY EXPRESSLY DISCLAIMS ANY AND
              ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. Company makes no representation that materials on
              this site are appropriate or available for use in locations outside the United States and accessing
              them from territories where their contents are illegal is prohibited. Those who choose to access this
              site from other locations do so on their own initiative and are responsible for compliance with local
              laws. Company does not warrant that (i) the content is accurate, reliable, or correct, (ii) the
              services will be available at any particular time or location, (iii) any defects or errors will be
              corrected, or (iv) the content is free of viruses or other harmful components. Your use of these
              services is solely at your own risk. Where any such exclusion of warranties is not permitted in your
              jurisdiction, such exclusion does not apply to you.
            </li>
            <li>
              <u>Not Responsible for Typographical Errors</u>. THE COMPANY HAS MADE EVERY EFFORT TO DISPLAY ITS
              PRODUCTS AND SERVICES AND OTHER THINGS YOU SEE ON THE WEBSITE AND APP AS ACCURATELY AS POSSIBLE. THE
              WEBSITE AND APP MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS OR OMISSIONS. THE COMPANY
              IS NOT RESPONSIBLE FOR ANY SUCH TYPOGRAPHICAL, TECHNICAL, OR PRICING ERRORS AND YOU ACKNOWLEDGE AND
              AGREE THAT YOU ARE NOT ENTITLED TO ANY BENEFITS THAT ARISE FROM SUCH AN ERROR.
            </li>
            <li>
              <u>Third Party Sites</u>. In the event you elect to participate in any third-party promotions or
              engage in third party services that may have been introduced by or otherwise promoted on the Website,
              App or a Company communication, any correspondences or participation with such third parties,
              including the delivery of and the payment for products, services, or content, are solely between you
              and each such third party. Company is not responsible and shall have no liability for any viruses or
              other illicit code that may be downloaded through a link to a third-party website.
            </li>
            <li>
              <u>Interruption to Website and Application</u>. Access to the Website and App services and their
              functions may from time-to-time be unavailable to User, whether because of technical failures or
              interruptions, intentional downtime for service or changes to the services, or otherwise, all of which
              are outside of the control of the Company. User agrees that any modification of the services, and any
              material interruption or unavailability of access to the services caused by third parties shall not
              constitute a default of any obligations of the Company under these Terms of Use, and, the Company
              shall have no liability of any nature to User for any such modifications, interruptions,
              unavailability, or failure of access. In the event the User experiences a material interruption or is
              unable to access content, please contact Company at: <EmailLink />
            </li>
          </ol>
        </li>
        <li>
          <b>LIMITATION OF LIABILITY</b>
          <ol type={'A'}>
            <li>
              THE COMPANY'S ENTIRE AND CUMULATIVE LIABILITY TO USER, OR ANY OTHER PARTY, FOR ANY LOSS OR DAMAGES
              RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR RELATING TO THESE TERMS OF USE, THE
              SERVICES OR CONTENT SHALL NOT EXCEED AN AMOUNT EQUAL TO $50.00 OR THE AMOUNT PAID FOR A PRODUCT OR
              SERVICE PURCHASED THROUGH THE WEBSITE.
            </li>
            <li>
              WITHOUT LIMITING AND NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL THE COMPANY BE LIABLE TO USER
              FOR ANY LOSS OF BUSINESS OR ANTICIPATORY PROFITS OR ANY INDIRECT, SPECIAL, INCIDENTAL, GENERAL,
              EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, EVEN IF FORESEEABLE OR IF THE COMPANY HAS
              BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, DAMAGE OR EXPENSE.
            </li>
            <li>
              ALTHOUGH THE COMPANY WILL USE COMMERCIALLY REASONABLE EFFORTS TO TAKE ALL ACTIONS IT DEEMS APPROPRIATE
              TO REMEDY AND AVOID DATA AND INTERNET INTERRUPTIONS, THE COMPANY CANNOT GUARANTEE THAT SUCH EVENTS
              WILL NOT OCCUR. ACCORDINGLY, THE COMPANY DISCLAIMS ANY AND ALL LIABILITY RESULTING FROM OR RELATED TO
              SUCH EVENTS.
            </li>
            <li>
              ALTHOUGH THE COMPANY WILL USE COMMERCIALLY REASONABLE EFFORTS TO TAKE ALL ACTIONS IT DEEMS APPROPRIATE
              TO REMEDY AND AVOID DAMAGE TO ART/CARDS/PHOTOS PROVIDED TO COMPANY, THE COMPANY CANNOT GUARANTEE THAT
              SUCH EVENTS WILL NOT OCCUR. ACCORDINGLY, THE COMPANY DISCLAIMS ANY AND ALL LIABILITY RESULTING FROM OR
              RELATED TO SUCH EVENTS THAT ARISE FROM NORMAL WEAR AND TEAR, THE FRAGILE CONDITION OF THE ART/CARDS/
              PHOTOS PROVIDED, OR COMPANY’S NEGLIGENCE.
            </li>
          </ol>
        </li>
        <li>
          <b>CONSUMER AND DEFAMATION NOTICES</b>
          <ol type={'A'}>
            <li>
              <u>Notification of Potential Infringement</u>. If any User believes its copyright, trademark or other
              property rights have been infringed by any content included or posted within the
              services, User should immediately send a notification to the Company's designated agent (“Designated
              Agent”), as identified below. To be effective, the notification must include: (i) A physical or
              electronic signature of a person authorized to act on behalf of the owner of an exclusive right that
              is allegedly infringed; (ii) Identification of the copyrighted work claimed to have been infringed;
              (iii) Information reasonably sufficient to permit the Company to contact the complaining party, such
              as address, telephone number and, if available, an electronic mail address at which the complaining
              party may be contacted; (iv) Identification of the material that is claimed to be infringing or to be
              subject to infringing activity and that is to be removed and information reasonably sufficient to
              permit the Company to locate the material; (v) A statement that the complaining party has a good faith
              belief that use of the material in the manner complained of is not authorized by the copyright owner,
              agent, or the law; and (vi) A statement that the information in the notification is accurate and,
              under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an
              exclusive right that is allegedly infringed. Pursuant to the Digital Millennium Copyright Act
              (“DMCA”), 17 U.S.C. § 512(c), the Company's Designated Agent for notice of claims of copyright
              infringement can be reached as indicated below. The Designated Agent shall be: Ruth McKee, Artkive,
              15800 Arminta Street, Van Nuys, CA 91406.
            </li>
            <li>
              <u>California Residents</u>. If this site charges for services, products, content, or information,
              pricing information will be posted as part of the ordering process for this site. We maintain specific
              contact information including an e-mail address for notifications of complaints and for inquiries
              regarding pricing policies in accordance with California Civil Code Section 1789.3. All correspondence
              should be addressed to our agent for notice at the following address:
              <p style={{ marginLeft: 24 }}>
                Notification of Consumer Rights Complaint or Pricing Inquiry: 3505 The Alameda, Santa Clara, CA
                95050.
              </p>
              You may contact us with complaints and inquiries regarding pricing and we will investigate those
              matters and respond to the inquiries. The Complaint Assistance Unit of the Division of Consumer
              Services of the Department of Consumer Affairs may be contacted in writing at 1020 N. Street, #501,
              Sacramento, CA 95814, or by telephone at 1-916-445-1254.
            </li>
            <li>
              <u>Defamation; Communications Decency Act Notice</u>. This site is a provider of "interactive computer
              services" under the Communications Decency Act, 47 U.S.C. Section 230, and as such, our liability for
              defamation and other claims arising out of any postings to this site by third parties is limited as
              described therein. We are not responsible for third-party content, or any other information posted to
              this site by third parties. We neither warrant the accuracy of such postings or exercise any editorial
              control over such posts, nor do we assume any legal obligation for editorial control of content posted
              by third parties or liability in connection with such postings, including any responsibility or
              liability for investigating or verifying the accuracy of any content or any other information
              contained in such postings.
            </li>
          </ol>
        </li>
        <li>
          <b>MISCELLANEOUS</b>
          <ol type={'A'}>
            <li>
              <u>Changes to Terms of Use</u>. The Company reserves the right, in its sole discretion, to modify,
              update, or otherwise revise these Terms of Use at any time. Such revisions shall be effective
              immediately upon posting revised Terms of Use on the Website or App. By using the Website or App after
              the Company has posted any modifications, updates or revisions, User agrees to be bound by such
              revised Terms of Use. In addition to these Terms of Use, additional terms may govern use of certain
              materials and events associated with the Company and services, and User agrees to be bound by such
              terms.
            </li>
            <li>
              <u>Choice of Law</u>. These Terms of Use shall be governed by and construed in accordance with the
              laws of the state of California.
            </li>
            <li>
              <u>Arbitration</u>. Mindful of the high cost of legal disputes, not only in dollars but also in time
              and energy, both you and the Company agree that any controversy, claim, action, or dispute in any way
              related to your use of any Website, the App, any purchase from Company, or to any products or services
              sold or distributed by the Company (“Dispute”) will be resolved by this dispute resolution procedure
              and arbitration agreement (“Arbitration Agreement”).
              <ol type={'i'}>
                <li>
                  Either party asserting the Dispute shall first try in good faith to settle such Dispute by
                  providing written notice to the other party (by first class or registered mail) describing the
                  facts and circumstances (including any relevant documentation) of the Dispute and allowing the
                  receiving party 30 days in which to respond to or settle the Dispute. Notice shall be sent via
                  email. Emails to the Company shall be sent to: <EmailLink />.
                </li>
                <li>
                  To the extent you cannot resolve any Dispute through the informal dispute resolution procedure
                  described above, a Dispute shall be resolved through binding individual arbitration. Accordingly,
                  you and the Company agree to give up the right to go to court to assert or defend rights under
                  this Arbitration Agreement and with respect to any Dispute (except small claims, as described
                  below). You and the Company expressly delegate to the arbitrator the authority to determine the
                  arbitrability of any Dispute, including the scope, applicability, validity, and enforceability of
                  this Arbitration Agreement.
                </li>
                <li>
                  Instead of arbitration, to the extent required by law, either you or the Company may bring any
                  individual claim in small claims court consistent with the jurisdictional and dollar limits that
                  may apply, as long as it is brought and maintained as an individual claim.
                </li>
                <li>
                  Either party may initiate arbitration, which shall be conducted by the American Arbitration
                  Association (“AAA”) pursuant to its Consumer Arbitration Rules (“AAA Rules”), as modified by this
                  Arbitration Agreement. The AAA Rules are available on the AAA’s website <Link
                    href={'https://www.adr.org'} />, or by calling the AAA at <a href={'tel:800.778.7879'}>(800)
                  778-7879</a>. In the event the AAA is unavailable or unwilling to hear the Dispute, the parties
                  shall agree to another arbitration provider. Payment of all filing, administration and arbitrator
                  fees will be governed by the AAA’s applicable rules. You may choose to have the arbitration
                  conducted by telephone, based on written submissions, or in person in Los Angeles County.
                </li>
                <li>
                  All arbitrations shall proceed on an individual basis. The arbitrator is empowered to resolve the
                  Dispute with the same remedies available in court, however, any relief must be individualized to
                  you and shall not affect any other customer. You and the Company agree that each may bring claims
                  against the other in arbitration only in your or their respective individual capacities and in so
                  doing you and the Company hereby waive the right to a trial by jury, to assert or participate in a
                  class action lawsuit or class action arbitration (either as a named-plaintiff or class member),
                  and to assert or participate in any joint or consolidated lawsuit or joint or consolidated
                  arbitration of any kind. If a court decides that applicable law precludes enforcement of any of
                  this paragraph’s limitations as to a particular cause of action, then that cause of action (and
                  only that cause of action) must remain in court and be severed from any arbitration.
                </li>
                <li>
                  This Arbitration Agreement shall be governed by, and interpreted, construed, and enforced in
                  accordance with the Federal Arbitration Act and, where applicable, the law of California. The
                  terms of the Arbitration Agreement provisions shall survive after your relationship with the
                  Company and/or use of our websites or other services and products ends. Except as set forth above,
                  if any portion of this Arbitration Agreement is deemed invalid or unenforceable, it will not
                  invalidate the remaining portions of the Arbitration Agreement.
                </li>
              </ol>
            </li>
            <li>
              <u>Injunction and Equitable Relief</u>. The Company and User agree that a breach or violation of many
              of these Terms of Use will result in immediate and irreparable injury and harm to the Company, where,
              in addition to any and all remedies of law and other consequences under these Terms of Use, Company
              shall have the right to an injunction, specific performance or other equitable relief to prevent the
              violation of the obligation under these Terms of Use.
            </li>
            <li>
              <u>No Waivers</u>. The waiver by either party of, or the failure of either party to take action with
              respect to, any breach of any term, covenant or condition contained in these Terms of Use shall not be
              deemed to be a waiver of such term, covenant or condition, or subsequent breach of the same, or any
              other term, covenant or condition contained in these Terms of Use.
            </li>
            <li>
              <u>Attorneys’ Fees</u>. If any action is brought by either party against the other party regarding the
              subject matter of these Terms of Use, the prevailing party shall be entitled to recover, in addition
              to any other relief granted, reasonable attorney's fees, costs, and expenses of arbitration.
            </li>
            <li>
              <u>Severability</u>. Any provision of these Terms of Use which is determined by a court of competent
              jurisdiction to be invalid or otherwise unenforceable shall not invalidate or make unenforceable any
              other provision of these Terms of Use.
            </li>
          </ol>
        </li>
      </ol>
    </Box>
  )
}

export default LatestBody
