import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

import ColoredStageIcon from './ColoredStageIcon'

const EstimationPaper = withStyles((muiTheme) => ({
  root: {
    backgroundColor: lighten(muiTheme.palette.info.main, 0.8),
    padding: muiTheme.spacing(1.5),
    margin: `${muiTheme.spacing(1.5)}px 0`,
  },
}), {
  withTheme: true,
})(Paper)

const useStyles = makeStyles((muiTheme) => ({
  root: {
    backgroundColor: '#FAFAFA',
    padding: muiTheme.spacing(3),
    fontSize: '0.875rem',
    color: theme.colors.black,
  },
  typography: {
    fontSize: 'inherit',
    color: 'inherit',
  },
  note: {
    fontStyle: 'italic',
  },
  divider: {
    margin: `${muiTheme.spacing(1.5)}px 0`,
  },
  activeStateIcon: {
    minWidth: 36,
    marginRight: muiTheme.spacing(1),
  },
  activeSpecialistName: {
    fontSize: 'inherit',
    color: 'inherit',
  },
}))

const OrderTrackingCurrentState = ({ activeStatus, details, specialist }) => {
  const classes = useStyles()

  const processing = [details.processing_start_range, details.processing_end_range].filter(Boolean).join(' - ')

  const estimation = `${processing} ${details.processing_time_type} after your artwork is received`

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant={'h6'} className={classes.typography}>
        Current Status
      </Typography>

      <Divider className={classes.divider} />

      <ListItem style={{ paddingLeft: 0 }}>
        <ListItemIcon className={classes.activeStateIcon}>
          <ColoredStageIcon icon={activeStatus.icon} color={activeStatus.color} />
        </ListItemIcon>
        <Typography variant={'h6'} className={classes.typography}>
          {activeStatus.title}
        </Typography>
      </ListItem>

      <EstimationPaper elevation={0}>
        <Typography variant={'h6'} className={classes.typography}>
          Estimated Order Completion:
        </Typography>
        <Typography variant={'body1'} className={classes.typography}>
          {estimation}
        </Typography>
      </EstimationPaper>

      {/* {activeStatus.status === ORDER_STATUS.EBOOK_DELIVERED && (
        <Link color='secondary' href={reviewLink} target='_blank'>
          <Button color='primary' fullWidth={true} size={'large'} variant='contained'>
            Review Book
          </Button>
        </Link>
      )}*/}

      <Typography variant={'body2'} className={cl(classes.typography, classes.note)}>
        Note: If you ordered a Framed Mosaic, its delivery timeline will be about two weeks longer than the book
        timeline.
      </Typography>

      <Divider className={classes.divider} />

      {specialist && (
        <ListItem alignItems={'flex-start'}>
          <ListItemAvatar>
            <Avatar alt={specialist.name} src={specialist.url} />
          </ListItemAvatar>
          <ListItemText
            primary={'Your Book Specialist'}
            secondary={specialist.name}
            classes={{ secondary: classes.typography }}
          />
        </ListItem>
      )}
    </Paper>
  )
}

OrderTrackingCurrentState.propTypes = {
  activeStatus: PropTypes.object,
  details: PropTypes.object,
  specialist: PropTypes.object,
}

export default OrderTrackingCurrentState
