import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import usePicturesBreakpoints from 'artkive/hooks/usePicturesBreakpoints'
import theme from 'artkive/utils/theme'

import topSectionBg from 'images/ecom/pricing/bg.svg'
import boxProduct from 'images/ecom/pricing/box.png'
import boxProductMobile from 'images/ecom/pricing/box-mobile.png'

import { PRICING, productBullets } from './Pricing.constants'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    background: `url(${topSectionBg}) center bottom no-repeat`,
    backgroundSize: 'cover',
    height: 660,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      // background: theme.colors.white.main,
      height: 'auto',
    },
  },
  title: {
    marginBottom: 24,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      textAlign: 'left',
      marginBottom: 16,
    },
  },
  digital: {
    position: 'absolute',
    top: 50,
    right: 50,
  },
  imageWrapper: {
    display: 'flex',
    position: 'relative',
  },
  image: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    maxWidth: 'unset',
    [breakpoints.up('lg')]: {
      // paddingLeft: spacing(20),
    },
  },
  artBackTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primary.dark,
    lineHeight: 0.8,
  },
  costSliderOuter: {
    background: '#E6F1FC',
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    position: 'relative',
  },
  costSliderInner: {
    flex: 1,
    border: '2px dashed #80CFFF',
    borderRadius: 8,
    padding: 20,
  },
  addonsScribble: {
    position: 'absolute',
    transform: 'rotate(0)',
    top: -10,
    right: -30,
    [breakpoints.up('md')]: {
      top: -30,
      right: -20,
    },
    [breakpoints.up('lg')]: {
      top: -30,
      right: 30,
    },
  },
  costHint: {
    '&&': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    padding: '0 8px',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      width: 120,
      textAlign: 'right',
    },
  },
}))

const TopSection = () => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()
  const { mediaUp } = usePicturesBreakpoints()

  return (
    <ContainedSection
      className={classes.wrapper}
      disableGutters={isMobile}
      py={0}
    >
      <Grid container direction={isMobile ? 'column' : 'row'}>
        <Grid item xs={12} md={6} className={classes.imageWrapper}>
          <picture>
            <source
              srcSet={boxProduct}
              media={mediaUp('md')}
            />
            <img src={boxProductMobile} className={classes.image} />
          </picture>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={isMobile ? 2 : 10} py={isMobile ? 0 : 12.5}>
            <Typography
              gutterBottom
              variant={'h2'}
              className={classes.title}
            >
              It All Starts<br />
              With An Artkive Box
            </Typography>

            {productBullets.map(({ text }, index) => (
              <ProductDescriptionItem key={index}>{text}</ProductDescriptionItem>
            ))}

            <Box
              display={'inline-flex'}
              mt={isMobile ? 2 : 2}
              mb={isMobile ? 2 : 0.5}
              alignItems={'baseline'}
              justifyContent={isMobile ? 'center' : 'flex-start'}
              width={'100%'}
            >
              <ProductPriceTypography>${PRICING.box.base}</ProductPriceTypography>
            </Box>

            <Box mb={4} textAlign={isMobile ? 'center' : 'left'}>
              <Typography variant={'caption'}>
                *${PRICING.box.base} is for the Artkive Box only. Cost of
                photography/editing, books, and/or mosaics not included
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

export default TopSection
