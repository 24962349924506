import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import NoSsr from 'common/components/NoSsr'
import Image from 'artkive/components/Image'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

import EGiftConfirmation from 'images/ecom/giftcards/EGiftCardConfirmation.png'
import Confirmation from 'images/ecom/giftcards/GC_Confirmation.png'

const BoxGradient = withStyles(() => ({
  root: {
    background: 'linear-gradient(90deg, rgba(255,242,209,1) 0%, rgba(255,242,209,1) 35%, rgba(250,250,250,1) 35%, rgba(250,250,250,1) 100%)',
  },
}))(Box)

const GiftCardConfirmation = ({ params, src, title, description, delivery }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <BoxGradient p={isMobile ? 0 : 8}>
      <Container>
        <Grid container spacing={isMobile ? 0 : 8}>
          <Grid item xs={12} md={6} style={{ display: 'flex' }} alignItems={'center'}>
            <Image src={src} />
          </Grid>

          <Grid item xs={12} md={6}>
            <NoSsr>
              <Box p={6}>
                <Box color={'secondary.dark'} fontWeight={'bold'} mb={2}>Order #{params.orderId}</Box>
                <Typography gutterBottom variant={'h1'}>
                  {title}
                </Typography>
                <Typography gutterBottom variant={'subtitle2'}>
                  {description}
                </Typography>
                <Box display={'flex'} fontWeight={'bold'}>
                  Recipient: &nbsp; <Typography>{params.name}</Typography>
                </Box>
                <Box display={'flex'} fontWeight={'bold'}>Delivery: &nbsp; <Typography>{delivery}</Typography></Box>
              </Box>
            </NoSsr>
          </Grid>
        </Grid>
      </Container>
    </BoxGradient>
  )
}

GiftCardConfirmation.propTypes = {
  params: PropTypes.object,
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  delivery: PropTypes.string,
}

export const PhysicalGiftCardConfirmation = ({ params }) => (
  <GiftCardConfirmation
    params={params}
    src={Confirmation}
    title={<>Your Gift Card is <br /> On the Way!</>}
    description={(
      <>
        We&apos;ve emailed you an order confirmation, and your gift will ship out shortly.
      </>
    )}
    delivery={'5 Business Days'}
  />
)

PhysicalGiftCardConfirmation.propTypes = {
  params: PropTypes.object,
}

export const EGiftCardConfirmation = ({ params }) => {
  const isImmediate = params.delivery === format(new Date(), 'yyyy-MM-dd')

  return (
    <GiftCardConfirmation
      params={params}
      src={EGiftConfirmation}
      title={<>Your e-Gift Card is <br /> {isImmediate? 'On the Way!' : 'Ready to Go!'}</>}
      description={isImmediate ? (
        <>
          We&apos;ve emailed you an order confirmation, and we&apos;ll send your gift right away!
        </>
      ): (
        <>
          We&apos;ve emailed you an order confirmation, and your gift will be emailed on the delivery date stated below.
        </>
      )}
      delivery={isImmediate ? 'Today' : params.delivery}
    />
  )
}

EGiftCardConfirmation.propTypes = {
  params: PropTypes.object,
}
