// eslint-disable-next-line simple-import-sort/imports
import 'core-js'

import ReactOnRails from 'react-on-rails'

// Shared Components
import Footer from 'artkive/components/Footer'
import Navbar from 'artkive/components/Navbar'
import RedeemBanner from 'artkive/components/RedeemBanner'
// Page Components
import About from 'artkive/pages/About'
import Books from 'artkive/pages/Books'
import BoxCancellation from 'artkive/pages/BoxCancellation'
import Checkout from 'artkive/pages/Checkout'
import Contact from 'artkive/pages/Contact'
import Digital from 'artkive/pages/Digital'
import GiftCardCheckout from 'artkive/pages/GiftCardCheckout'
import Home from 'artkive/pages/Home'
import Information from 'artkive/pages/Information'
import Mosaics from 'artkive/pages/Mosaics'
import OrderTracking from 'artkive/pages/OrderTracking'
import OurProcess from 'artkive/pages/OurProcess'
import PackagesCheckout from 'artkive/pages/PackagesCheckout'
import PlansCheckout from 'artkive/pages/PlansCheckout'
import Pricing from 'artkive/pages/Pricing'
import Privacy from 'artkive/pages/Privacy'
import SharedPicture from 'artkive/pages/SharedPicture'
import Tou from 'artkive/pages/Tou'

ReactOnRails.register({
  About,
  Books,
  Pricing,
  Checkout,
  PackagesCheckout,
  BoxCancellation,
  OrderTracking,
  Contact,
  Digital,
  Footer,
  GiftCardCheckout,
  Home,
  Information,
  Mosaics,
  Navbar,
  OurProcess,
  PlansCheckout,
  Privacy,
  RedeemBanner,
  SharedPicture,
  Tou,
})
