import Process from 'images/ecom/process/Process1.png'
import Process2 from 'images/ecom/process/Process2.png'
import Process3 from 'images/ecom/process/Process3.png'
import Process4 from 'images/ecom/process/Process4.png'

export const defaultHowItWorks = [
  {
    image: Process,
    title: 'Order an Artkive Box\n & Send Us Your Art',
    body: 'We\'ll send you a box to fill with all your favorite art pieces and an order form to select a Book, Mosaic, or both.',
  },
  {
    image: Process2,
    title: 'Professional Photography & Editing',
    body: 'We\'ll individually photograph every piece of artwork in your box and then edit each image to show off the artwork in its best light.',
  },
  {
    image: Process3,
    title: 'Make Final Payment',
    body: 'You\'ll receive an invoice based on the number of art pieces you\'ve sent and the product(s) you\'ve selected.',
  },
  {
    image: Process4,
    title: 'Design & Enjoy',
    body: 'Our creative team will thoughtfully design your Book or Mosaic and send you a proof to review, customize and approve for print.',
  },
]

