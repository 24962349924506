import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, FormControlLabel, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CCInput from 'common/components/CCInput'
import ExpDate from 'common/components/ExpDate'
import Input from 'common/components/Form/Input'
import routes from 'artkive/utils/routes'
import validations from 'artkive/utils/validations'

import SectionGrid from '../Checkout/SectionGrid'

import FreeOrderPaper from './FreeOrderPaper'

const useStyles = makeStyles(({ breakpoints }) => ({
  tosLabel: {
    color: '#1E202B',
    padding: '9px 0',
  },
  leftPadding: {
    [breakpoints.down('sm')]: {
      paddingLeft: 8,
    },
  },
}))

const PaymentInformationFragment = ({ disabled }) => {
  const { unregister, register } = useFormContext()
  const classes = useStyles()

  const rules = useMemo(() => {
    if (disabled)
      return {}

    return {
      name: validations.name,
      cardNumber: validations.cardNumber,
      cardExp: validations.cardExp,
      cardCvc: validations.cardCvc,
      billingZipCode: validations.zipCode,
    }
  }, [disabled])

  useEffect(() => {
    const fields = [
      'name',
      'cardNumber',
      'cardExp',
      'cardCvc',
      'billingZipCode',
    ]

    if (disabled) {
      for (const field of fields) {
        unregister(field, {
          keepDefaultValue: true,
        })
      }
    } else {
      for (const field of fields) {
        register(field)
      }
    }
  }, [disabled])

  const { setValue } = useFormContext()

  const handleInputEvent = (e) => {
    setValue(
      e.target.getAttribute('name'),
      e.target.value,
      {
        shouldValidate: true,
      },
    )
  }

  return (
    <SectionGrid>
      <Grid item xs={12}>
        <Input
          name={'name'}
          rules={rules.name}
          label={'Name On Card'}
          disabled={disabled}
          autoComplete={'cc-name'}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={'cardNumber'}
          rules={rules.cardNumber}
          label={'Card Number'}
          disabled={disabled}
          autoComplete={'cc-number'}
          InputProps={{ inputComponent: CCInput }}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          name={'cardExp'}
          rules={rules.cardExp}
          label={'Exp Date'}
          disabled={disabled}
          autoComplete={'cc-exp'}
          InputProps={{ inputComponent: ExpDate }}
          // inputProps={{ inputMode: 'numeric', maxLength: 4 }}
          placeholder={'MM/YY'}
        />
      </Grid>

      <Grid item xs={4} className={classes.leftPadding}>
        <Input
          name={'cardCvc'}
          rules={rules.cardCvc}
          label={'CVC'}
          disabled={disabled}
          autoComplete={'postal-code'}
          inputProps={{ inputMode: 'numeric', maxLength: 4 }}
        />
      </Grid>

      <Grid item xs={4} className={classes.leftPadding}>
        <Input
          name={'billingZipCode'}
          rules={rules.zipCode}
          label={'Postal Code'}
          disabled={disabled}
          autoComplete={'cc-csc'}
          onInput={handleInputEvent}
        />
      </Grid>

      {disabled && <FreeOrderPaper />}

      <Grid item xs={12}>
        <Controller
          name={'tos'}
          render={({ field }) => (
            <FormControlLabel
              classes={{ label: classes.tosLabel }}
              control={<Checkbox color={'primary'} {...field} value={field.value} />}
              label={(
                <>
                  I agree with
                  {' '}<Link href={routes.tou} target={'_blank'}>Terms of Use</Link> and
                  {' '}<Link href={routes.privacy} target={'_blank'}>Privacy Policy</Link>
                </>
              )}
            />
          )}
        />
      </Grid>
    </SectionGrid>
  )
}

PaymentInformationFragment.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
}

export default PaymentInformationFragment
