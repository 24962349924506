import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Divider, Hidden } from '@material-ui/core'
import { addHours } from 'date-fns'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Route, Switch, useLocation } from 'wouter'

import { withNoSsr } from 'common/components/NoSsr'
import useClosure from 'common/hooks/useClosure'
import CheckoutWrapper from 'artkive/components/Checkout/CheckoutWrapper'
import GiftCardBulletPoints from 'artkive/components/Checkout/GiftCardBulletPoints'
import useAddressSuggestion from 'artkive/components/Checkout/hooks/useAddressSuggestion'
import AddressValidation from 'artkive/components/Modals/AddressValidation'
import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import { TrackerContext } from 'artkive/components/Tracker'
import useTracker from 'artkive/components/Tracker/useTracker'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import { E_GIFTCARD_PRODUCT, GIFT_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/giftCard.store'
import { GIFT_CAD_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import * as track from 'artkive/utils/tracker'

import ContactInformation from './Steps/ContactInformation'
import OrderDetails from './Steps/OrderDetails'
import Payment from './Steps/Payment'
import ContactInformationSummary from './ContactInformationSummary'
import GiftCardProductPreview from './GiftCardProductPreview'
import RecipientDetailsSummary from './RecipientDetailsSummary'

// TODO: figure out why it is different from GIFT_CAD_PRODUCT_TYPE
const productType = 'giftcard'

const GiftCardCheckout = ({ params, productLinks, enableRecaptcha = false }) => {
  const [pathname, navigate] = useLocation()
  const [trackAction] = useTracker()
  const {
    cacheTrackingData,
    subscribe,
    unsubscribe,
  } = useContext(TrackerContext)

  // state
  const [error, setError] = useState()

  const {
    checkAddress,
    closeSuggestion,
    suggestion,
  } = useAddressSuggestion()

  // redux
  const { setLoader } = useStoreActions(({ uiStore }) => uiStore)
  const {
    placeOrder,
    reset,
    setInformation,
    setPayment,
    setStep,
    setStepActive,
    setProduct,
    fetchProducts,
    setConfirmation,
  } = useStoreActions(({ giftCardStore }) => giftCardStore)

  const {
    activeStep,
    information,
    product,
    products,
    qty,
    steps,
    price,
  } = useStoreState(({ giftCardStore }) => giftCardStore)

  const handleSetLoading = (opt) => {
    setLoader(opt)
  }

  useEffect(() => {
    fetchProducts({ type: params.uuid })
  }, [])

  useEffect(async () => {
    // skip product update after refresh
    if (!params?.uuid)
      return

    console.log(params.uuid, products[params.uuid])
    setProduct(products[params.uuid])
  }, [params?.uuid, products])

  useEffect(() => {
    window.scrollTo(0, 0)

    const alias = pathname.replace('/', '')
    const routeStepIndex = steps.findIndex((step) => step.alias === alias.toLowerCase())

    // Check prev step completed
    if (routeStepIndex === -1 || !steps[routeStepIndex - 1]?.isComplete) {
      // navigate('')

      setStepActive({ stepName: GIFT_CHECKOUT_STEPS.SHIPPING })
    } else {
      setStepActive({ stepName: alias || GIFT_CHECKOUT_STEPS.SHIPPING })
    }
  }, [pathname])

  const handleInformationInfo = (data) => {
    // set user information
    setInformation(data)
    // set current step as valid
    setStep({ stepName: GIFT_CHECKOUT_STEPS.INFORMATION, payload: { isComplete: true } })

    // update route
    navigate(`/${GIFT_CHECKOUT_STEPS.PAYMENT}`)
  }

  const handleShippingInfo = async (data) => {
    console.log('handleInfo', data)
    if (!data.isValid && product.cardType !== E_GIFTCARD_PRODUCT.cardType) {
      const { error, response } = await checkAddress(data)
      if (error) {
        setError(error)
      }

      if (error || response) {
        return
      }

      console.log('check address')
    }

    // set user information
    setInformation(data)

    await trackAction(GIFT_CARDS.CHECKOUT_NEXT_CLICK, { price })

    // set current step as valid
    await setStep({ stepName: GIFT_CHECKOUT_STEPS.SHIPPING, payload: { isComplete: true } })

    // update route
    navigate(`/${GIFT_CHECKOUT_STEPS.INFORMATION}`)

  }

  const handlePlaceOrder = async (payment) => {
    handleSetLoading(true)
    try {
      // month date strings
      const expArray = payment.cardExp.split('/')
      const expMonth = expArray[0]
      const expYear = expArray[1]
      const recipientName = [information.recipientFirstName, information.recipientLastName].filter(Boolean).join(' ')

      const orderInformation = {
        payment_method: {
          cc: payment.cardNumber.replace(/\s+/g, ''),
          cvc: payment.cardCvc,
          exp_month: expMonth,
          exp_year: `20${expYear}`,
          name_on_card: payment.name,
        },
        details: {
          type: product.cardType,
          buyer_name: payment.name,
          buyer_email: information.email,
          phone_number: information.phone.replace(/\D+/g, ''),
          billing_zip: payment.billingZipCode,
          shipping_name: recipientName,
          gift_message: information.note,
          message_to: recipientName,
          message_from: `${information.firstName} ${information.lastName}`,
          quantity: qty,
        },
        'g-recaptcha-response-data': payment['g-recaptcha-response-data'],
      }

      if (product.cardType === E_GIFTCARD_PRODUCT.cardType) {
        Object.assign(orderInformation.details, {
          shipping_address: information.toEmail,
          shipping_city: null,
          shipping_state: null,
          shipping_zip_code: null,
          delivery_date: addHours(new Date(information.deliveryDate), 10).toISOString(),
        })
      } else {
        Object.assign(orderInformation.details, {
          shipping_address: [information.address, information.address2].filter(Boolean).join(', '),
          shipping_city: information.city,
          shipping_state: information.state,
          shipping_country: information.country,
          shipping_zip_code: information.zipCode,
          delivery_date: information.deliveryDate,
        })
      }

      // submit order
      const { error, response } = await placeOrder(orderInformation)

      if (error) {
        setError(error.response.data.error)
        // redirect to payment on server error
        navigate(`/${GIFT_CHECKOUT_STEPS.PAYMENT}`)
      } else {
        const { amount, buyer_email, buyer_name, id, shipping_name } = response.data.data

        await track.purchase({
          amount: amount,
          discount: 0,
          orderId: id,
          name: buyer_name,
          productId: product.type,
          product: productType,
          information: { email: buyer_email },
        })

        await reset()

        await setConfirmation({
          name: shipping_name,
          orderId: id,
          type: product.cardType,
          delivery: information.deliveryDate,
        })
        navigate(`~/giftcards/${params.uuid}/confirmation`)
      }
    } finally {
      handleSetLoading(false)
    }
  }

  const handlePayment = async (data) => {
    // set payment info
    setPayment(data)

    await handlePlaceOrder(data)
  }

  const prepareEventData = useClosure(() => ({
    formType: 'information',
    kind: 'checkout',
    product: product.type,
    email: information.email,
    phone: information.phone,
    firstName: information.firstName,
    lastName: information.lastName,
  }), [information, product])

  // on mount set params
  useEffect(() => {
    cacheTrackingData(prepareEventData(), true)

    // clean url
    navigate('')

    track.initiateCheckout({ email: information.email, product: productType })

    const subscribeHandler = () => {
      cacheTrackingData(prepareEventData())
    }

    subscribe('blur', subscribeHandler)

    return () => {
      unsubscribe('blur', subscribeHandler)
    }
  }, [])

  if (!activeStep)
    return null

  return (
    <CheckoutWrapper
      product={product}
      error={error}
      setError={setError}
      backTo={`~${productLinks.landing}`}
      details={(
        <Switch>
          <Route path={`/${GIFT_CHECKOUT_STEPS.INFORMATION}`}>
            <GiftCardProductPreview product={product} />

            <Hidden smDown>
              <GiftCardBulletPoints type={product.cardType} />
              <Box my={4}><Divider /></Box>
            </Hidden>

            <RecipientDetailsSummary product={product} />
          </Route>
          <Route path={`/${GIFT_CHECKOUT_STEPS.PAYMENT}`}>
            <GiftCardProductPreview product={product} />

            <Hidden smDown>
              <GiftCardBulletPoints type={product.cardType} />
              <Box my={4}><Divider /></Box>
            </Hidden>

            <RecipientDetailsSummary product={product} />
            <ContactInformationSummary product={product} />
          </Route>
          <Route path={'/'}>
            <CheckoutProductPreview product={product} />

            <GiftCardBulletPoints type={product.cardType} />
          </Route>
        </Switch>
      )}
    >
      <Switch>
        <Route path={`/${GIFT_CHECKOUT_STEPS.INFORMATION}`}>
          <ContactInformation
            onSubmit={handleInformationInfo}
            product={product}
            productType={GIFT_CAD_PRODUCT_TYPE}
          />
        </Route>
        <Route path={`/${GIFT_CHECKOUT_STEPS.PAYMENT}`}>
          <Payment
            isLoading={false}
            onSubmit={handlePayment}
            product={product}
            enableRecaptcha={enableRecaptcha}
          />
        </Route>
        <Route path={'/'}>
          <OrderDetails
            onSubmit={handleShippingInfo}
            product={product}
            productType={GIFT_CAD_PRODUCT_TYPE}
          />
        </Route>
      </Switch>
      {suggestion && (
        <AddressValidation
          isOpen={!!suggestion}
          suggestion={suggestion}
          onSubmit={handleShippingInfo}
          setIsOpen={closeSuggestion}
        />
      )}
    </CheckoutWrapper>
  )
}

GiftCardCheckout.propTypes = {
  params: PropTypes.object.isRequired,
  productLinks: PropTypes.object.isRequired,
  enableRecaptcha: PropTypes.bool,
}

export default withNoSsr(GiftCardCheckout)
