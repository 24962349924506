import React, { useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { MAX_NUMBER_OF_PAGES, MIN_BOX_BOOK_NUMBER_OF_PAGES } from 'common/utils/bookSize.constants'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ProductX2 from 'images/ecom/books/ArtBooksProduct-x2.png'
import Product from 'images/ecom/pricing/ArtBooksProduct.png'
import ProductMobile from 'images/ecom/pricing/ArtBooksProduct-mobile.png'

import PriceSlider from '../Books/PriceSlider'
import { getPrice } from '../Books/ValueLabelComponent'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #fff 50%, #D3EFFB 50%);',
    height: 800,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      background: theme.colors.white.main,
      height: 'auto',
    },
  },
  container: {
    padding: 0,
  },
  digital: {
    position: 'absolute',
    top: 50,
    right: 50,
  },
  description: {
    display: 'flex',
    alignItems: 'center',
  },
  imageWrapper: {
    paddingLeft: 30,
    display: 'flex',
    position: 'relative',
    [breakpoints.down('sm')]: {
      background: '#D3EFFB',
      paddingLeft: 0,
    },
  },
  image: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    maxWidth: 'unset',
    [breakpoints.up('lg')]: {
      // paddingLeft: spacing(20),
    },
  },
  artBackTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primary.dark,
    lineHeight: 0.8,
  },
  costSliderOuter: {
    background: '#E6F1FC',
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    position: 'relative',
  },
  costSliderInner: {
    flex: 1,
    border: '2px dashed #80CFFF',
    borderRadius: 8,
    padding: 20,
  },
  addonsScribble: {
    position: 'absolute',
    transform: 'rotate(0)',
    top: -10,
    right: -30,
    [breakpoints.up('md')]: {
      top: -30,
      right: -20,
    },
    [breakpoints.up('lg')]: {
      top: -30,
      right: 30,
    },
  },
  costHint: {
    '&&': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    height: 74,
    border: '2px solid #E6F1FC',
    borderRadius: 8,
    padding: '0 8px',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      width: 120,
      textAlign: 'right',
    },
  },
  price: {
    fontSize: '1.5rem',
    lineHeight: 1.5,
  },
  sliderRail: {
    left: -16,
    right: -16,
    width: 'calc(100% + 32px)',
  },
  sliderTrack: {
    transform: 'translateX(-16px)',
  },
}))

export const bookProductBullets = [
  { text: 'Professional photography, editing of each piece of art, and a digital proof to review' },
  {
    text: `Books range from ${MIN_BOX_BOOK_NUMBER_OF_PAGES}-${MAX_NUMBER_OF_PAGES} images and ` +
      'come in two sizes: 8"x8" or 11"x8.5"',
  },
  { text: 'Book cost is dependent on number of art pieces sent.' },
]

const BooksSection = ({ pricing }) => {
  const classes = useStyles()
  const muiTheme = useTheme()

  const [sliderValue, setSliderValue] = useState(MIN_BOX_BOOK_NUMBER_OF_PAGES)

  const handleSliderChange = (e, value) => setSliderValue(value)

  const isMobile = useMobileBreakpoint()

  return (
    <ContainedSection
      className={classes.wrapper}
      disableGutters={isMobile}
      py={0}
    >
      <Grid container direction={isMobile ? 'column-reverse' : 'row'}>
        <Grid item xs md={6} className={classes.description}>
          <Box pr={isMobile ? 2 : 15} pl={2} py={isMobile ? 4 : 0}>
            <Typography variant={'h2'} align={isMobile ? 'center' : 'left'}>
              Art Books
            </Typography>

            <Box mt={3} mb={4}>
              {bookProductBullets.map(({ text }, index) => (
                <ProductDescriptionItem key={index}>
                  {text}
                </ProductDescriptionItem>
              ))}
            </Box>

            <Paper elevation={0} className={cl(classes.costHint)}>
              <Typography component={'span'} className={'test-pricing-label'}>{sliderValue} Pieces</Typography>
              <Typography component={'span'} style={{ padding: '0 12px' }}>—</Typography>
              <Typography variant={'h6'} className={cl(classes.price, 'test-pricing-amount')}>
                {getPrice(sliderValue, pricing)}
              </Typography>
            </Paper>

            <Box px={2} my={3} flex={1} width={'100%'}>
              <PriceSlider
                value={sliderValue}
                onChange={handleSliderChange}
                classes={{ rail: classes.sliderRail, track: classes.sliderTrack }}
              />
            </Box>

            <Box mb={4}>
              <Typography variant={'caption'} component={Box} mb={2}>
                *The pricing slider is per book. If you send more than {MAX_NUMBER_OF_PAGES}{' '}
                pieces for one book, we will split them into multiple book
                volumes
              </Typography>
              <Typography variant={'caption'} component={Box}>
                * Price does not include Artkive Box
              </Typography>
            </Box>

            <Box
              display={'flex'}
              justifyContent={isMobile ? 'center' : 'left'}
              mt={isMobile ? 4 : 5}
              mb={isMobile ? 4 : 6}
            >
              <TrackButton
                color={'primary'}
                href={routes.boxCheckout}
                size={'large'}
                variant={'contained'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_books_section',
                }}
              >
                Get My Box
              </TrackButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs md={6} className={classes.imageWrapper}>
          <picture className={classes.image}>
            <source
              srcSet={`${Product}`}
              media={muiTheme.breakpoints.up('lg').replace('@media ', '')}
            />
            <source
              srcSet={`${ProductX2}, ${Product} 2x`}
              media={muiTheme.breakpoints.up('md').replace('@media ', '')}
            />
            <img src={ProductMobile} className={classes.image} />
          </picture>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

BooksSection.propTypes = {
  pricing: PropTypes.object.isRequired,
}

export default BooksSection
