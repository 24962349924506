import React from 'react'

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import Social from 'artkive/components/Social'
import TeamCarousel from 'artkive/components/TeamCarousel'
import { TrackButton, withClickTracker } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { GET_BOX_CLICK, NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Badge from 'images/ecom/process/Process-Badges-Careful.svg'
import Badge2 from 'images/ecom/process/Process-Badges-Quality.svg'
import Badge3 from 'images/ecom/process/Process-Badges-Team.svg'
import Badge4 from 'images/ecom/process/Process-Badges-Updates.svg'
import Process from 'images/ecom/process/Process1.png'
import Process2 from 'images/ecom/process/Process2.png'
import Process3 from 'images/ecom/process/Process3.png'
import Process4 from 'images/ecom/process/Process4.png'

import GetStartedFooter from '../Home/GetStartedFooter'

import HeadSection from './HeadSection'

const TrackSwipe = withClickTracker(Button)

const howItWorksSteps = [
  {
    image: Process,
    title: 'We send you an Artkive box',
    body: 'The Artkive box is more than a box: it\'s the beginning of your Artkive experience. It\'s sturdy, insured, and large enough to safely send us a lot of art (for multiple kids, too!). Once you order a box, it\'s time to fill it up! Bring on the joyful memories.',
  },
  {
    image: Process2,
    title: 'Our creative process begins',
    body: 'Once we get your Artkive box back to our studio, we get to work doing what we love! We\'ll professionally photograph and edit each piece with studio lighting and state-of-the-art equipment. Once everything is beautifully captured, we\'ll send you an invoice based on the number of pieces you sent.',
  },
  {
    image: Process3,
    title: 'It\'s time to collaborate',
    body: 'Our design team creates a digital proof of your art book or framed mosaic, then sends it to you so you can review, edit if needed, and ultimately approve. Once you give us the green light, we\'ll send your proof to press!',
  },
  {
    image: Process4,
    title: 'Your art is Artkived!',
    body: 'We send your custom keepsake to you (with free shipping, as always). We\'ll also send back your art if you\'ve selected that option at checkout. If not, we\'ll responsibly recycle it. It\'s time to place your beautiful new art book on your coffee table, framed mosaic in a place of honor, and enjoy! At this point we recommend collective high-fives, hugs, and major pats on the back. You did it! ',
  },
]

const useAboutItemStyles = makeStyles({
  image: {
    borderRadius: 8,
  },
  item: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  stepIndex: {
    color: '#7A68AE',
    fontWeight: 700,
  },
})

const AboutHowItWorksItem = ({
  index,
  title,
  text,
  image,
  order,
}) => {
  const classes = useAboutItemStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Box my={4}>
      <Grid container spacing={isMobile ? 0 : 4} direction={isMobile ? 'column-reverse' : order}>
        <Grid item xs={12} md={6} className={classes.item}>
          <Box px={isMobile ? 2 : 8}>
            <Typography variant={'body1'} className={classes.stepIndex}>
              STEP {index}
            </Typography>
            <Box mb={2} mt={2}>
              <Typography variant={'h3'} align={isMobile ? 'center' : 'left'}>
                {title}
              </Typography>
            </Box>
            <Typography variant={'body1'} align={isMobile ? 'center' : 'left'}>
              {text}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Image src={image} className={classes.image} />
        </Grid>
      </Grid>
    </Box>
  )
}

const OurProcess = () => {
  usePageScrollTracker()

  const isMobile = useMobileBreakpoint()

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      {/* I CAN BE YOUR HERO BABAY */}
      <HeadSection />
      {/* I CAN BE YOUR HERO BABAY */}

      {/* This is a story all about how */}

      {isMobile ? (
        <HowItWorks
          steps={howItWorksSteps}
          title={'Here’s How It Works'}
          subtitle={(
            <Box mt={3}>
              <Typography align={'center'}>
                How the art you love becomes a keepsake you'll treasure
              </Typography>
            </Box>
          )}
          variant={'image'}
          bgcolor={'white'}
        >
          <Typography align={'center'}>
            How the art you love becomes a keepsake you'll treasure
          </Typography>
          <Box justifyContent={'center'} display={'flex'} mt={isMobile ? 6 : 8}>
            <TrackButton
              color={'primary'}
              variant={'contained'}
              href={routes.boxCheckout}
              trackerEvent={GET_BOX_CLICK}
              trackerOptions={{ section: 'out_process' }}
              size={'large'}
            >
              Get My Box
            </TrackButton>
          </Box>
        </HowItWorks>
      ) : (
        <ContainedSection
          title={(
            <>
              <Typography gutterBottom variant={'h2'} align={'center'}>
                Here’s How It Works
              </Typography>
              <Typography align={'center'}>
                How the art you love becomes a keepsake you'll treasure
              </Typography>
            </>
          )}
          bgcolor={'white'}
        >
          {howItWorksSteps.map((step, index) => (
            <AboutHowItWorksItem
              key={index}
              order={index % 2 ? 'row-reverse' : 'row'}
              title={step.title}
              text={step.body}
              image={step.image}
              index={index + 1}
            />
          ))}

          <Box justifyContent={'center'} display={'flex'} mt={isMobile ? 6 : 8}>
            <TrackButton
              color={'primary'}
              variant={'contained'}
              href={routes.boxCheckout}
              trackerEvent={GET_BOX_CLICK}
              trackerOptions={{ section: 'home_how_it_works' }}
              size={'large'}
            >
              Get My Box
            </TrackButton>
          </Box>
        </ContainedSection>
      )}
      {/* This is a story all about how */}

      {/* Quabity Ashuitz */}
      <Box py={8}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={Badge} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}>
                  <Typography gutterBottom style={{ fontWeight: 'bold', color: theme.colors.primary.dark }}>
                    Handled  With Care
                  </Typography>
                </Box>
                <Typography>We handle each piece with love. No feathers will be lost in the process.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={Badge2} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}>
                  <Typography gutterBottom style={{ fontWeight: 'bold', color: theme.colors.primary.dark }}>
                    Quality Guarantee
                  </Typography>
                </Box>
                <Typography>
                  Our quality standards are high. If something&apos;s wrong, we&apos;ll make it right.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={Badge3} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}>
                  <Typography gutterBottom style={{ fontWeight: 'bold', color: theme.colors.primary.dark }}>
                    Dedicated Support
                  </Typography>
                </Box>
                <Typography>From start to finish, we&apos;re committed to taking care of you like family. </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={Badge4} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}>
                  <Typography gutterBottom style={{ fontWeight: 'bold', color: theme.colors.primary.dark }}>
                    Real-Time Updates
                  </Typography>
                </Box>
                <Typography>
                  Stay informed with email updates and access to your <strong><Link href={routes.orderTracking} target={'_blank'}>Order Status Tracking page</Link></strong>.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <ContainedSection
        bgcolor={'white'}
        disableGutters={isMobile}
        title={(
          <>
            <Box mb={4} px={2}>
              <Typography align={'center'} variant={'h2'}>A creative dream team</Typography>
            </Box>
            <Box mb={4} px={isMobile ? 2 : 10} mx={isMobile ? 0 : 10}>
              <Typography align={'center'}>
                We&apos;re a passionate team of creatives who&apos;ve come together with the
                same mission: to lift up the creators of tomorrow and leave no piece of art behind.
              </Typography>
            </Box>
          </>
        )}>

        <TeamCarousel />

        <Box align={'center'} mt={6}>
          <TrackSwipe
            variant={'outlined'}
            size={'large'}
            color={'primary'}
            underline={'none'}
            href={routes.aboutUs}
            trackerEvent={NAVIGATION.ABOUT_US_CLICK}
            trackerOptions={{
              section: 'about_us_banner',
            }}
          >
            About Us
          </TrackSwipe>
        </Box>
      </ContainedSection>

      {/* Join The Fun  */}
      <Social />
      <GetStartedFooter />
    </Box>
  )
}

export default withApp(OurProcess)
