import React, { useState } from 'react'

import { Box, Container, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { withClickTracker } from 'artkive/components/Tracker'
import { OUR_PROCESS } from 'artkive/constants/tracker/mainNavigation'
import theme from 'artkive/utils/theme'

import Hero from 'images/ecom/process/Process-Hero.png'

const TrackVideoOpenBox = withClickTracker(Box)

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  wrapper: {
    backgroundImage: `url(${Hero})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '360px',
    position: 'relative',
    [breakpoints.up('md')]: {
      minHeight: '400px',
    },
    [breakpoints.up('lg')]: {
      minHeight: '450px',
    },
  },
  picture: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.sky[900],
    },
  },
  playIcon: {
    width: 80,
    height: 80,
  },
  text: {
    maxWidth: '35rem',
  },
}))

const HeadSection = () => {
  const classes = useStyles()
  // state
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TrackVideoOpenBox
        onClick={() => setIsOpen(true)}
        cursor={'pointer'}
        trackerEvent={OUR_PROCESS.PLAY_VIDEO_CLICK}
        className={classes.wrapper}
      >
        <Container className={classes.container}>
          <Box className={classes.playBtn}>
            <Box mb={3}>
              <Typography style={{ color: 'white' }} variant={'h1'}>
                It All Starts With Art
              </Typography>
            </Box>

            <svg
              width={'104'}
              height={'104'}
              viewBox={'0 0 104 104'}
              fill={'none'}
              xmlns={'http://www.w3.org/2000/svg'}
              className={classes.playIcon}
            >
              <path d={'M24.375 24.375H79.625V79.625H24.375V24.375Z'} fill={'white'} />
              <path
                d={'M51.9974 8.66406C28.0774 8.66406 8.66406 28.0774 8.66406 51.9974C8.66406 75.9174 28.0774 95.3307 51.9974 95.3307C75.9174 95.3307 95.3307 75.9174 95.3307 51.9974C95.3307 28.0774 75.9174 8.66406 51.9974 8.66406ZM43.3307 71.4974V32.4974L69.3307 51.9974L43.3307 71.4974Z'}
                fill={'currentColor'}
              />
            </svg>

            <Typography component={'div'} color={'inherit'}>
              <strong>See How We Work</strong>
            </Typography>
          </Box>
        </Container>
      </TrackVideoOpenBox>

      {/* video modal */}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <iframe
          width={'560'}
          height={'315'}
          src={'https://www.youtube.com/embed/WkC6OZDsRDE'}
          title={'YouTube video player'}
          frameBorder={'0'}
          allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
          allowFullScreen
        >
        </iframe>
      </Dialog>
    </>
  )
}

export default HeadSection
