import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'react-hook-form'
import { Box, Divider, Hidden } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { useStoreActions } from 'easy-peasy'
import { useLocation } from 'wouter'

import { withNoSsr } from 'common/components/NoSsr'
import useClosure from 'common/hooks/useClosure'
import { GENERAL_ERROR_MESSAGE } from 'common/utils/data/messages'
import CheckoutWrapper from 'artkive/components/Checkout/CheckoutWrapper'
import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import OrderPriceSummary from 'artkive/components/OrderSummary/OrderPriceSummary'
import Promo from 'artkive/components/Promo'
import { TrackerContext } from 'artkive/components/Tracker'
import useTracker from 'artkive/components/Tracker/useTracker'
import { BOX_CHECKOUT } from 'artkive/constants/tracker/mainNavigation'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { normalizePhoneNumber } from 'artkive/pages/Checkout/utils'
import { PACKAGE_PRODUCT_TYPE, PAYMENT_KINDS } from 'artkive/stores/product.constants'
import * as track from 'artkive/utils/tracker'

import ShippingAndPayment from './Steps/ShippingAndPayment'
import ExtraCopiesAddon from './ExtraCopiesAddon'

const buildPaymentMethod = (payment, freeOrder) => {
  if (!payment || Object.keys(payment).length === 0 || freeOrder) return

  const expArray = payment.cardExp.split('/')

  return {
    method: PAYMENT_KINDS.CREDIT_CARD,
    name_on_card: payment.name,
    credit_card_number: payment.cardNumber.replace(/\s+/g, ''),
    exp_month: expArray[0],
    exp_year: `20${expArray[1]}`,
    security_code: payment.cardCvc,
  }
}

const Checkout = withNoSsr(({ productData, params, productLinks }) => {
  const [error, setError] = useState()
  const [trackAction] = useTracker()

  const { cacheTrackingData, subscribe, unsubscribe } = useContext(TrackerContext)

  const [, navigate] = useLocation()

  const { setLoader } = useStoreActions(({ uiStore }) => uiStore)
  const {
    orderPackage,
    reset,
    setPayment,
    setProduct,
  } = useVariableActions(PACKAGE_PRODUCT_TYPE, productData.uuid)

  const {
    validate,
    checkPromoForFutureUse,
    setPromo,
    tryToReusePromo,
  } = useStoreActions(({ promoStore }) => promoStore)

  const {
    fullName,
    information,
    price,
    product,
    freeOrder,
    addOns,
    promoCode,
  } = useVariableState(PACKAGE_PRODUCT_TYPE, productData.uuid)

  // is required to be able to create a price getter to pass it to payment options
  const [, setLocalPrice] = useState({})

  useEffect(() => {
    setLocalPrice(price)
  }, [price])

  const data = useMemo(() => ({
    id: productData.id,
    cover_url: get(productData, 'cover_image.url', ''),
    preview_url: get(productData, 'preview_image.url', ''),
    name: get(productData, 'name', ''),
    back_link: `~${productLinks.landing}`,
    shipping_address: get(productData, 'properties.shipping_address', false),
    taxable: get(productData, 'properties.taxable', false),
    adjust_quantity: get(productData, 'properties.adjust_quantity', false),
    book_copies_price: get(productData, 'properties.options.book_copies.price', 0),
  }), [productData])

  const handleSuccessPurchase = useCallback(async (res, paymentKind) => {
    const { id, pricing_object, promo_discount_amount } = res.response.data.data
    const { promo_code, total } = pricing_object

    track.purchase({
      amount: total,
      discount: promo_discount_amount,
      orderId: id,
      name: fullName,
      productId: productData.id,
      product: PACKAGE_PRODUCT_TYPE,
      promo: promo_code.code,
      paymentKind,
      information: { email: information.email },
    })

    try {
      await Promise.all([validate, reset])
      await checkPromoForFutureUse()
    } catch (error) {
      console.error('Checkout processing exception', JSON.stringify(error))
      try {
        Sentry.captureException(error)
      } catch (sentryError) {
        console.error('Sentry exception', JSON.stringify(sentryError))
      }
    } finally {
      const redirectParams = new URLSearchParams({ orderId: id }).toString()
      navigate(`~${productLinks.confirmation}?${redirectParams}`)
      setLoader(false)
    }
  }, [productData, information, fullName, productLinks])

  const handlePlaceOrder = async (payment) => {
    setPayment(payment)

    await trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })

    setLoader(true)

    const paymentData = buildPaymentMethod(payment, freeOrder)

    const packageOrder = {
      concierge_product_id: productData.id,
      promo_code: promoCode,
      name: fullName,
      email: information.email,
      phone_number: normalizePhoneNumber(information.phone),

      billing_zip: payment.billingZipCode,
      add_ons: addOns,
    }

    if (payment.recorder === 'else') {
      packageOrder.gift_message = information.note
      packageOrder.gift_send_at = information.deliveryDate
      packageOrder.recipient_email = information.toEmail
      packageOrder.recipient_name = [information.recipientFirstName, information.recipientLastName].filter(Boolean).join(' ')
      packageOrder.recipient_phone_number = normalizePhoneNumber(payment.toPhone)
    }

    try {
      const res = await orderPackage({
        package_order: packageOrder,
        payment_method: paymentData,
        'g-recaptcha-response-data': payment['g-recaptcha-response-data'],
      })

      if (res.error) {
        setError(res.error?.response?.data?.message || GENERAL_ERROR_MESSAGE)
        // NOTE: this allows to read all error messages details from back-end
        console.warn(JSON.stringify(res.error?.response?.data?.errors || {}))

        setLoader(false)
      } else {
        handleSuccessPurchase(res, PAYMENT_KINDS.CREDIT_CARD)
      }
    } finally {
      setLoader(false)
    }
  }

  const prepareEventData = useClosure(() => ({
    formType: 'information',
    kind: 'checkout',
    product: product.type,
    email: information.email,
    phone: information.phone,
    firstName: information.firstName,
    lastName: information.lastName,
  }), [information, product])

  useEffect(() => {
    cacheTrackingData(prepareEventData(), true)

    const { promo } = params

    // set product pricing based on params passed from rails erb
    setProduct({
      id: productData.id,
      uuid: productData.uuid,
      title: productData.name,
      msrp: 0,
      price: productData.sale_price,
      image: data.preview_url || data.cover_url,
      isTaxable: data.taxable,
    })

    const payload = {
      code: promo || promoCode,
      product_id: productData.id,
      uuid: productData.uuid,
      productName: PACKAGE_PRODUCT_TYPE,
    }
    // set promo
    if (promo) {
      setPromo(payload)
    } else {
      tryToReusePromo(payload)
    }

    const subscribeHandler = () => {
      cacheTrackingData(prepareEventData())
    }

    subscribe('blur', subscribeHandler)

    return () => {
      unsubscribe('blur', subscribeHandler)
    }
  }, [])

  if (!product.id)
    return null

  return (
    <CheckoutWrapper
      product={product}
      error={error}
      setError={setError}
      backTo={data.back_link}
      details={(
        <>
          <CheckoutProductPreview product={product} options={data} />

          <Box my={4}><Divider /></Box>

          {data.book_copies_price && (
            <ExtraCopiesAddon product={product} price={data.book_copies_price} />
          )}

          <Hidden smDown>
            <Box my={4}><Divider /></Box>

            <Promo product={product} />
          </Hidden>

          <Hidden mdUp>
            <Box my={3}><Divider /></Box>
            <Promo product={product} />

            <Box mt={3}><Divider /></Box>
            <OrderPriceSummary product={product} />
          </Hidden>
        </>
      )}
    >
      <ShippingAndPayment
        isLoading={false}
        onSubmit={handlePlaceOrder}
        product={product}
        options={data}
        setError={setError}
      />
    </CheckoutWrapper>
  )
})

Checkout.propTypes = {
  boxPrice: PropTypes.number,
  params: PropTypes.object,
  shippingProtectionPrice: PropTypes.number,
  productData: PropTypes.object,
  productLinks: PropTypes.object,
}

export default memo(Checkout)
