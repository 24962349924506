import React from 'react'

import { Box, Container, Link, Typography } from '@material-ui/core'
import { Route, Switch } from 'wouter'

import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import withApp from 'artkive/hoc/withApp'
import routes from 'artkive/utils/routes'

import LatestBody from './LatestBody'
import V20240710Body from './V20240710Body'
import V20250128Body from './V20250128Body'

const Tou = () => {
  usePageScrollTracker()

  return (
    <Box py={10}>
      <Container>
        <Box>
          <Typography align={'center'} variant={'h2'}>Terms of Use</Typography>
          <br />
          <ul>
            <li>
              <Link href={routes.tou}>
                <strong>Updated Terms of Use</strong>
              </Link>
            </li>
            <li>
              <Link href={routes.tou + '/2025-01-28'}>
                <strong>Previous Terms of Use July 11, 2024 - Jan 28, 2025</strong>
              </Link>
            </li>
            <li>
              <Link href={routes.tou + '/2024-07-10'}>
                <strong>Previous Terms of Use May 24, 2022 - July 10, 2024</strong>
              </Link>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <Switch>
            <Route path={routes.tou + '/2025-01-28'} component={V20250128Body} />
            <Route path={routes.tou + '/2024-07-10'} component={V20240710Body} />
            <Route component={LatestBody} />
          </Switch>
        </Box>
      </Container>
    </Box>
  )
}

export default withApp(Tou)
