import { makeStyles } from '@material-ui/core/styles'

import theme, { newColors } from 'artkive/utils/theme'

const baseLabel = {
  fontWeight: 600,
  textAlign: 'right',
  color: theme.colors.black,
  whiteSpace: 'nowrap',
}

const baseFontSize = {
  regular: '1rem',
  small: '0.875rem',
  crossed: '0.8125rem',
  crossedSmall: '0.75rem',
  regularLarge: '1.25rem',
  large: '1.5rem',
}

const baseFontSizeMobile = {
  regular: '1.125rem',
  small: '1rem',
  crossed: '0.875rem',
  smaller: '0.6875rem',
}

const baseLineHeight = {
  regular: 1.5,
  reduced: 1.4,
}

const basePrice = {
  lineHeight: baseLineHeight.reduced,
  fontWeight: 500,
  textDecoration: 'line-through',
  color: newColors.grey[700],
}

const baseDiscountBox = {
  padding: '0 4px',
  borderRadius: '4px',
  backgroundColor: newColors.red[700],
}

const usePriceWithDiscountsStyles = makeStyles(({ breakpoints }) => ({
  priceLabel: {
    ...baseLabel,
    fontSize: baseFontSize.regular,
    lineHeight: baseLineHeight.regular,
    marginTop: '3px',
  },
  priceLabelWithDiscount: {
    ...baseLabel,
    fontSize: baseFontSize.regular,
    lineHeight: baseLineHeight.reduced,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.small,
      lineHeight: baseLineHeight.regular,
    },
  },
  priceLabelWithDiscountOrderSummary: {
    ...baseLabel,
    fontSize: baseFontSize.regularLarge,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.regular,
      lineHeight: baseLineHeight.reduced,
    },
  },
  priceLabelWithDiscountAddon: {
    ...baseLabel,
    fontWeight: 500,
    fontSize: baseFontSize.regular,
    lineHeight: baseLineHeight.reduced,
    color: newColors.grey[700],
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.small,
    },
  },
  priceLabelWithDiscountAddonSummary: {
    color: theme.colors.black,
  },
  priceLabelWithDiscountOrderSummaryUSBDiscount: {
    color: newColors.grey[700],
  },
  priceLabelWithDiscountAddonSelected: {
    ...baseLabel,
    fontWeight: 600,
    color: theme.colors.black,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.small,
    },
  },

  priceLabelWithDiscountAddonUSB: {
    ...baseLabel,
    textDecoration: 'line-through',
    fontWeight: 500,
    fontSize: baseFontSize.regular,
    color: newColors.grey[700],
  },
  priceLabelMobileOrderDetails: {
    ...baseLabel,
    fontSize: baseFontSizeMobile.crossed,
    lineHeight: baseLineHeight.reduced,
  },
  priceLabelMobileOrderDetailsAddon: {
    ...baseLabel,
    fontSize: baseFontSizeMobile.crossed,
    fontWeight: 500,
  },
  price: {
    ...basePrice,
    fontSize: baseFontSize.crossed,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.crossed,
    },
  },
  priceOrderSummary: {
    ...basePrice,
    fontSize: baseFontSize.small,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.small,
    },
  },
  priceProductDescription: {
    ...basePrice,
    fontSize: baseFontSize.large,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.regular,
    },
  },
  priceAddon: {
    ...basePrice,
    fontSize: baseFontSize.crossed,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.crossed,
    },
  },
  priceMobileOrderDetails: {
    ...basePrice,
    fontSize: baseFontSizeMobile.crossed,
  },
  priceMobileOrderDetailsAddon: {
    ...basePrice,
    fontSize: baseFontSizeMobile.crossed,
  },

  discountBox: {
    ...baseDiscountBox,
    marginLeft: '6px',
  },

  discountBoxProductDescription: {
    ...baseDiscountBox,
    marginLeft: '8px',
  },
  discountBoxAddon: {
    ...baseDiscountBox,
    backgroundColor: newColors.red[100],
    marginLeft: '4px',
  },
  discountBoxAddonUSB: {
    ...baseDiscountBox,
    marginLeft: '4px',
  },
  discountBoxMobileOrderDetails: {
    ...baseDiscountBox,
    marginLeft: '4px',
  },
  discountBoxMobileOrderDetailsAddon: {
    ...baseDiscountBox,
    backgroundColor: newColors.red[100],
    marginLeft: '4px',
  },
  discountLabel: {
    ...baseLabel,
    color: theme.colors.white.main,
    fontSize: baseFontSize.small,
    lineHeight: baseLineHeight.reduced,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.crossed,
    },
  },
  discountLabelProductDescription: {
    ...baseLabel,
    color: theme.colors.white.main,
    fontSize: baseFontSize.regular,
    lineHeight: baseLineHeight.reduced,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.crossed,
    },
  },
  discountLabelAddon: {
    ...baseLabel,
    color: newColors.red[700],
    fontSize: baseFontSize.small,
    lineHeight: baseLineHeight.reduced,
    [breakpoints.down('sm')]: {
      fontSize: baseFontSizeMobile.smaller,
      fontWeight: 500,
    },
  },
  discountLabelAddonUSB: {
    ...baseLabel,
    fontSize: baseFontSize.small,
    color: theme.colors.white.main,
    lineHeight: baseLineHeight.reduced,
  },
  discountLabelMobileOrderDetails: {
    ...baseLabel,
    color: theme.colors.white.main,
    fontSize: baseFontSizeMobile.crossed,
  },
  discountLabelMobileOrderDetailsAddon: {
    ...baseLabel,
    color: newColors.red[700],
    fontSize: baseFontSizeMobile.smaller,
  },
}))

export default usePriceWithDiscountsStyles
