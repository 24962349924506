import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { PRICING } from 'artkive/pages/Pricing/Pricing.constants'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ArtBookImage from 'images/ecom/books/ArtBooks.png'
import MosaicsImage from 'images/ecom/pricing/Mosaics.jpg'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#F5F9FA',
    [breakpoints.down('sm')]: {
      backgroundColor: '#E3F2FD',
    },
    borderRadius: 8,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.black,
    fontWeight: 'bold',
    fontSize: 20,
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  infoIcon: {
    fontSize: '2rem',
    color: '#102371',
    marginRight: 12,
  },
  description: {
    padding: '8px 0 0 0',
    fontSize: 16,
    color: theme.colors.grey.dark,
  },
  boxRoot: {
    display: 'flex',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '24px',
  },
  image: {
    width: 100,
    height: 90,
    objectFit: 'cover',
    borderRadius: 2,
  },
  boxTitle: {
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  boxText: {
    fontSize: 14,
    marginBottom: 8,
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginBottom: '5px',
    fontSize: 14,
  },
}))

const ConfirmationCostsBox = ({ mosaicPricing }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Box
      className={classes.root}
      px={isMobile ? 2 : 3}
      py={3}
      mt={isMobile ? 6 : 5}
    >
      <Box className={classes.title}>
        <InfoOutlinedIcon className={classes.infoIcon} /> Art Book and Framed Mosaic cost:
      </Box>
      <Typography className={classes.description}>
        The cost of your keepsake is separate from your Artkive Box,
        and it depends on how many pieces of art you send back in your box.
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.box}>
          <img src={ArtBookImage} className={classes.image} />
          <Box px={2} py={0}>
            <Typography className={classes.boxTitle}>
              Art Book
            </Typography>
            <Typography className={classes.boxText}>
              Starting at ${PRICING.books.base} for {PRICING.books.minPages} pieces
            </Typography>
            <Link className={classes.link} href={routes.books}>
              Learn More
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.box}>
          <img src={MosaicsImage} className={classes.image} />
          <Box px={2}>
            <Typography className={classes.boxTitle}>
              Framed Mosaic
            </Typography>
            <Typography className={classes.boxText}>
              ${mosaicPricing.large_product.price}
            </Typography>
            <Link className={classes.link} href={routes.mosaics}>
              Learn More
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

ConfirmationCostsBox.propTypes = {
  mosaicPricing: PropTypes.object.isRequired,
}

export default ConfirmationCostsBox
