import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

export const getPrice = (value, { min_book_price, page_price_schema }) => {
  let total = min_book_price
  page_price_schema.forEach(({ lower, upper, price }) => {
    if (value >= lower) {
      total += Math.min(value - lower + 1, upper - lower + 1) * price
    }
  })

  return `$${Math.ceil(total)}`
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    border: `2px solid ${theme.colors.sky[300]}`,
    borderRadius: 8,
    padding: 16,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      width: 320,
    },
  },
  price: {
    fontSize: '1.5rem',
    lineHeight: 1.5,
  },
}))

const ValueLabelComponent = ({ value, className, pricing }) => {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={cl(classes.root, className)}>
      <Typography className={'test-pricing-label'}>{value} Pieces</Typography>
      <Typography variant={'h6'} className={cl(classes.price, 'test-pricing-amount')}>
        {getPrice(value, pricing)}
      </Typography>
    </Paper>
  )
}

ValueLabelComponent.propTypes = {
  value: PropTypes.string.isRequired,
  pricing: PropTypes.object.isRequired,
  className: PropTypes.string,
}

ValueLabelComponent.defaultProps = {
  className: null,
}

export default ValueLabelComponent
